import { useEffect } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import { translate, Translate } from "react-i18nify"
import { FaCamera, FaLock } from "react-icons/fa"
import { useParams } from "react-router"
import { Accordion, Button, Link, Stack, Text, VStack } from "@chakra-ui/react"
import { logoAtom, useAtom } from "store"
import { useGetEquipment } from "hooks"
import {
  EquipmentAvailability,
  EquipmentDocuments,
  EquipmentIdentification,
  EquipmentInterventions,
  EquipmentSpecs,
  EquipmentTelematic,
  LoadingSkeleton,
} from "components"
import { setPageTitle } from "utils"

export const Equipment = () => {
  const { guid } = useParams()

  let { data: equipment, isLoading, error } = useGetEquipment(guid, true)
  const [, setLogo] = useAtom(logoAtom)

  const hasAction = (action) => {
    const actions = equipment?.currentDigitalTwinOwner?.organization?.publicShareEqptActions ?? []
    return !!actions.find((a) => a === action)
  }

  useEffect(() => {
    if (equipment) {
      const logo = equipment.currentDigitalTwinOwner?.organization?.logo ?? null
      setLogo(
        logo ? { ...logo, alt: equipment.currentDigitalTwinOwner?.organization?.name ?? "" } : null
      )
      setPageTitle(equipment.name)
    } else {
      setPageTitle(translate("common.loading"))
    }
  }, [equipment, setLogo])

  if (error) {
    return null
  }

  if (isLoading) {
    return <LoadingSkeleton />
  }

  return (
    <>
      <EquipmentIdentification equipment={equipment} />
      <Accordion defaultIndex={[0]} allowMultiple>
        <EquipmentInterventions equipment={equipment} />
        <EquipmentDocuments equipment={equipment} />
        <EquipmentAvailability equipment={equipment} />
        <EquipmentSpecs equipment={equipment} />
        <EquipmentTelematic equipment={equipment} />
      </Accordion>
      <Stack mt="2rem" alignItems="center">
        <VStack align="stretch">
          <Link
            flex={1}
            href={`${process.env.REACT_APP_FRONT_BASEURL}/equipments/qrcode/${equipment.qrCode}`}
            title={translate(`application.view_in_${process.env.REACT_APP_CUSTOM_STYLES}`)}
          >
            <Button
              leftIcon={<FaLock color="white" />}
              maxW="300px"
              my="0.75rem"
              variant="important"
              width="100%"
            >
              <Translate value={`application.view_in_${process.env.REACT_APP_CUSTOM_STYLES}`} />
            </Button>
          </Link>

          {hasAction("new_use") && (
            <Link
              flex={1}
              href={`${process.env.REACT_APP_FRONT_BASEURL}/equipments/qrcode/${equipment.qrCode}?tab=equipmentUsers/{currentDigitalTwinOwner.@id}?equipmentId={@id}`}
              title={translate(`application.new_use`)}
            >
              <Button maxW="300px" w my="0.75rem" variant="important" width="100%">
                <Translate value="application.new_use" />
              </Button>
            </Link>
          )}
          {hasAction("ask_for_intervention") && (
            <Link
              flex={1}
              href={`${process.env.REACT_APP_FRONT_BASEURL}/equipments/qrcode/${equipment.qrCode}?tab=maintenance/interventions/interventionRequest`}
              title={translate(`application.ask_for_intervention`)}
            >
              <Button maxW="300px" my="0.75rem" variant="important" width="100%">
                <Translate value="application.ask_for_intervention" />
              </Button>
            </Link>
          )}
          {equipment?.currentDigitalTwinOwner?.organization?.weProovEnabled &&
            hasAction("new_inspection") && (
              <>
                <MobileView>
                  <Link
                    flex={1}
                    href={`${process.env.REACT_APP_FRONT_BASEURL}/equipments/qrcode/${
                      equipment.qrCode
                    }?tab=${encodeURIComponent("inspections/equipmentInspection")}`}
                    title={translate(`application.new_inspection`)}
                  >
                    <Button
                      leftIcon={<FaCamera color="white" />}
                      maxW="300px"
                      my="0.75rem"
                      width="100%"
                      variant="important"
                    >
                      <Translate value="application.new_inspection" />
                    </Button>
                  </Link>
                </MobileView>
              </>
            )}
        </VStack>

        {equipment?.currentDigitalTwinOwner?.organization?.weProovEnabled &&
          hasAction("new_inspection") && (
            <BrowserView>
              <Text>
                <Translate value="application.new_inspection_for_mobile_only" />
              </Text>
            </BrowserView>
          )}
      </Stack>
    </>
  )
}
